// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politica-de-privacidade-index-tsx": () => import("./../../../src/pages/politica-de-privacidade/index.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-index-tsx" */),
  "component---src-pages-solucoes-compliance-index-tsx": () => import("./../../../src/pages/solucoes/compliance/index.tsx" /* webpackChunkName: "component---src-pages-solucoes-compliance-index-tsx" */),
  "component---src-pages-solucoes-controladoria-apuracao-de-custos-tsx": () => import("./../../../src/pages/solucoes/controladoria/apuracao-de-custos.tsx" /* webpackChunkName: "component---src-pages-solucoes-controladoria-apuracao-de-custos-tsx" */),
  "component---src-pages-solucoes-controladoria-estudos-viabilidade-financeira-tsx": () => import("./../../../src/pages/solucoes/controladoria/estudos-viabilidade-financeira.tsx" /* webpackChunkName: "component---src-pages-solucoes-controladoria-estudos-viabilidade-financeira-tsx" */),
  "component---src-pages-solucoes-controladoria-formacao-do-preco-de-venda-tsx": () => import("./../../../src/pages/solucoes/controladoria/formacao-do-preco-de-venda.tsx" /* webpackChunkName: "component---src-pages-solucoes-controladoria-formacao-do-preco-de-venda-tsx" */),
  "component---src-pages-solucoes-controladoria-gestao-por-indicadores-kpis-tsx": () => import("./../../../src/pages/solucoes/controladoria/gestao-por-indicadores-kpis.tsx" /* webpackChunkName: "component---src-pages-solucoes-controladoria-gestao-por-indicadores-kpis-tsx" */),
  "component---src-pages-solucoes-controladoria-index-tsx": () => import("./../../../src/pages/solucoes/controladoria/index.tsx" /* webpackChunkName: "component---src-pages-solucoes-controladoria-index-tsx" */),
  "component---src-pages-solucoes-gestao-por-processos-de-negocio-bpm-aderencia-de-sistemas-tsx": () => import("./../../../src/pages/solucoes/gestao-por-processos-de-negocio-bpm/aderencia-de-sistemas.tsx" /* webpackChunkName: "component---src-pages-solucoes-gestao-por-processos-de-negocio-bpm-aderencia-de-sistemas-tsx" */),
  "component---src-pages-solucoes-gestao-por-processos-de-negocio-bpm-escritorio-de-processos-tsx": () => import("./../../../src/pages/solucoes/gestao-por-processos-de-negocio-bpm/escritorio-de-processos.tsx" /* webpackChunkName: "component---src-pages-solucoes-gestao-por-processos-de-negocio-bpm-escritorio-de-processos-tsx" */),
  "component---src-pages-solucoes-gestao-por-processos-de-negocio-bpm-index-tsx": () => import("./../../../src/pages/solucoes/gestao-por-processos-de-negocio-bpm/index.tsx" /* webpackChunkName: "component---src-pages-solucoes-gestao-por-processos-de-negocio-bpm-index-tsx" */),
  "component---src-pages-solucoes-gestao-por-processos-de-negocio-bpm-mapeamento-e-melhoria-de-processos-tsx": () => import("./../../../src/pages/solucoes/gestao-por-processos-de-negocio-bpm/mapeamento-e-melhoria-de-processos.tsx" /* webpackChunkName: "component---src-pages-solucoes-gestao-por-processos-de-negocio-bpm-mapeamento-e-melhoria-de-processos-tsx" */),
  "component---src-pages-solucoes-gestao-por-processos-de-negocio-bpm-treinamento-bpm-tsx": () => import("./../../../src/pages/solucoes/gestao-por-processos-de-negocio-bpm/treinamento-bpm.tsx" /* webpackChunkName: "component---src-pages-solucoes-gestao-por-processos-de-negocio-bpm-treinamento-bpm-tsx" */),
  "component---src-pages-solucoes-okr-objetivos-e-resultados-chaves-index-tsx": () => import("./../../../src/pages/solucoes/okr-objetivos-e-resultados-chaves/index.tsx" /* webpackChunkName: "component---src-pages-solucoes-okr-objetivos-e-resultados-chaves-index-tsx" */),
  "component---src-pages-solucoes-sistema-de-gestao-integrada-index-tsx": () => import("./../../../src/pages/solucoes/sistema-de-gestao-integrada/index.tsx" /* webpackChunkName: "component---src-pages-solucoes-sistema-de-gestao-integrada-index-tsx" */)
}

