const loading = require('./src/utils/LoadingContext')

exports.onRouteUpdate = () => {
  // loading.LoadingContext.setLoading()
}

exports.onInitialClientRender = () => {
  console.log('ReactDOM.render has executed')
  setTimeout(() => {
    loading.LoadingContext.disabledLoading()
  }, 2000)
}

exports.onRouteUpdateDelayed = () => {
  console.log('We can show loading indicator now')
  loading.LoadingContext.disabledLoading()
}
